import React from 'react';

const Intro = () => {
  return (
    <section id="firm-introduction">
      <div className="hero">
        <div className="hero-content">
          <h2 className="hero-heading">
            Real Estate<span>.</span><br />Business Law<span>.</span><br />Probate<span>.</span>
          </h2>

          <p className="hero-text">
            Established in 1980, The Law Offices of Thomas W. Madonna has provided clients in Rhode Island and Massachusetts with
            expert legal representation for over thirty five years.  Our firm offers a wide range of services
            in real estate, commercial, business and corporate law as well as probate and estate administration.
            If you are in need of professional legal advice contact us today to schedule a consultation.
          </p>

          <a href="#contact" className="mlf-btn hero-btn">Contact Us</a>
        </div>
      </div>
    </section>
  );
};

export { Intro };
