import React from 'react';

const TestimonialRealEstate = () => {
  return (
    <section className="testimonial-2">
      <div className="testimonial-col-small">
        <div className="testimonial-overlay"></div>
      </div>

      <div className="testimonial-col-large">
        <div className="testimonial-content">
          <blockquote className="testimonial-quote">
            Our service and dedication to our Clients, Realtors and Lenders has been the hallmark for the success and respect we have achieved over the years.
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export { TestimonialRealEstate };
