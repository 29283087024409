import React from 'react';

export const Footer = () => {
  return (
    <footer>
      <p className="text-center">
        <span>The Law Offices of Thomas W. Madonna</span>{` `}<span className="copyright">&copy; {copyrightYear}</span>.
      </p>
    </footer>
  );
};

const copyrightYear = new Date().getFullYear();
