import React from "react";

export const Header = () => {
  return (
    <header>
      <nav className="mlf-navbar px-md-4">
        <h1 className="mlf-logo-text">
          <a href="#firm-introduction">The Law Offices of Thomas W. Madonna</a>
        </h1>

        <ul className="list-unstyled mlf-nav-list">
          <li>
            <a href="#services">
              <span className="mlf-icon mlf-icon-briefcase material-icons-outlined" aria-hidden>work_outline</span>
              <span className="mlf-navbar-text">Services</span>
            </a>
          </li>

          <li>
            <a href="#biography">
            <span className="mlf-icon mlf-icon-profile-1 material-icons-outlined" aria-hidden>account_circle</span>
              <span className="mlf-navbar-text">Bio</span>
            </a>
          </li>

          <li>
            <a href="#contact">
              <span className="mlf-icon mlf-icon-smartphone-1 material-icons-outlined" aria-hidden>phone_iphone</span>
              <span className="mlf-navbar-text">Contact</span>
            </a>
          </li>
        </ul>

        <button className="mlf-menu-open-btn">
          <i className="mlf-icon mlf-icon-add-1"></i>
        </button>
      </nav>
    </header>
  )
};
