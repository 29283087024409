import React from 'react';
import headshot from "../images/madonna-headshot.jpg";

const Bio = () => {
  return (
    <section id="biography" className="biography">
      <div className="row">
        <div className="col-lg-5 text-center">
          <h3 className="section-heading bio-heading">Bio</h3>

          <div className="headshot-wrapper">
            <img src={headshot} alt="Thomas W. Madonna" className="img-fluid headshot" />

            <p className="headshot-caption">Thomas W. Madonna<br />Founding Owner<br />Law Offices of Thomas W. Madonna</p>
          </div>

          <p className="intro-text">
            We practice law,<br className="visible-md" /> we teach law,<br className="visible-md" /> we speak law.<br />
            We strive to be a firm that is <span>well&ndash;respected</span> within the community.
          </p>
        </div>

        <div className="col-sm-10 col-md-8 mx-auto mx-lg-0 col-lg-6 ms-lg-auto">
          <div className="bio-section">
            <h4 className="bio-section-heading">Bar Membership</h4>

            <ul className="bio-list">
              <li>Rhode Island</li>
              <li>Massachusetts</li>
              <li>United States Supreme Court</li>
              <li>Federal District Court, First Circuit for The State of Rhode Island</li>
            </ul>
          </div>

          <div className="bio-section">
            <h4 className="bio-section-heading">Teaching</h4>

            <ul className="bio-list">
              <li>Former Lecturer of Business Law, Providence College</li>
            </ul>
          </div>

          <div className="bio-section">
            <h4 className="bio-section-heading">Professional/Service Affiliations</h4>

            <ul className="bio-list">
              <li>Rhode Island Bar Association</li>
              <li>Massachusetts Bar Association</li>
              <li>United States Supreme Court</li>
              <li>Fellow, Rhode Island Bar Foundation</li>
              <li>Rhode Island Conveyancers Association</li>
              <li>Rhode Island Title Standards and Practices Committee</li>
              <li>Former Trustee, The Wheeler School, Board of Trustees</li>
              <li>Past President, Suffolk University Alumni Council</li>
              <li>Past President, Suffolk University Law School Alumni Board of Directors</li>
              <li>Past President, Suffolk University Law School Alumni, Rhode Island Chapter</li>
              <li>Former Vice Chair, Suffolk University Law School Dean's Advisory Committee</li>
              <li>Has closed and supervised 26,000 residential and commercial closings</li>
              <li>Former Chair, Rhode Island Supreme Court Unauthorized Practice of Law Committee</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Bio } ;
