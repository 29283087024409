import React from "react";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="text-center">
        <h3 className="section-heading contact-heading">Contact</h3>

        <address className="contact-list">
          The Law Offices of Thomas W. Madonna<br />
          70 Jefferson Boulevard<span className="d-none d-sm-inline">,</span><br className="d-sm-none" /> Warwick RI 02888<br />
          Email: <a href="mailto:info@madonnalawfirm.com?subject=Madonna Law Firm Inquiry" target="_blank" rel="noopener">info@madonnalawfirm.com</a><br />
          Phone: <a href="tel:+14017854400" target="_blank" rel="noopener">401.785.4400</a><br />
          Fax: 401.785.4404
        </address>
      </div>

      <div className="text-center contact-icons-wrapper">
        <a className="facebook" href="http://www.facebook.com/Law-Offices-of-Thomas-W-Madonna-169874993061748/" target="_blank" rel="noopener">
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Facebook</title>
            <path d="M6.387 12.782h2.63v10.83c0 .215.174.388.388.388h4.46a.387.387 0 0 0 .388-.387v-10.78h3.024a.387.387 0 0 0 .385-.342l.459-3.988a.387.387 0 0 0-.385-.431h-3.483v-2.5c0-.753.406-1.135 1.206-1.135h2.277a.387.387 0 0 0 .387-.387V.39a.387.387 0 0 0-.387-.387h-3.139A3.077 3.077 0 0 0 14.454 0c-.545 0-2.438.107-3.934 1.483-1.656 1.524-1.426 3.35-1.371 3.666v2.923H6.387A.387.387 0 0 0 6 8.459v3.936c0 .214.173.387.387.387Z" fill="var(--color-white)" fillRule="nonzero"/>
          </svg>
        </a>

        <a className="email icon-margin" href="mailto:info@madonnalawfirm.com?subject=Madonna Law Firm Inquiry" target="_blank" rel="noopener">
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Email</title>
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm0 14H4V8l8 5 8-5v10Zm-8-7L4 6h16l-8 5Z" fill="var(--color-white)" fillRule="nonzero" />
          </svg>
        </a>

        <a className="linked-in" href="http://www.linkedin.com/pub/thomas-madonna/30/3a9/632" target="_blank" rel="noopener">
          <svg viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
            <title>linkedin</title>
            <g fill="var(--color-white)" fillRule="nonzero">
              <path d="M0 6h4v11H0z"/>
              <circle cx="2" cy="2" r="2"/>
              <path d="M13.5 5.25A4.473 4.473 0 0 0 10 6.953V6H6v11h4v-7a2 2 0 1 1 4 0v7h4V9.75a4.5 4.5 0 0 0-4.5-4.5Z"/>
            </g>
          </svg>
        </a>
      </div>

      <div id="map" className="map">
        <iframe
          title="Madonna Law Firm Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2976.648082932427!2d-71.43721368416156!3d41.749682979232624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e44ef46fb4d3a1%3A0x51c0772c27788c03!2sThe%20Law%20Offices%20of%20Thomas%20W.%20Madonna!5e0!3m2!1sen!2sus!4v1645300865895!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy">
        </iframe>
      </div>
    </section>
  );
};

export { Contact };
