import React from 'react';

const Services = () => {
  return (
    <section id="services" className="services">
      <h3 className="text-center section-heading services-heading">Services</h3>

      <p className="text-center intro-text">
        Our goal is simple.<br className="d-inline d-md-none" />
        We provide <span>quality representation</span>.<br />
        It's what we've done for thirty&ndash;five years.
      </p>

      <div className="service-card-container">
        <div className="row">
          <div className="col-sm-8 mx-auto mx-lg-0 col-lg-4 d-flex">
            <div className="text-center service-card">
              <div className="service-header">
                <span className="material-icons-outlined mlf-icon mlf-icon-house-2" aria-hidden>home</span>
                <h4 className="service-heading">Real Estate</h4>
              </div>

              <div className="service-content service-card__content">
                <p>
                  Our firm has conducted and supervised more than 22,000 closings.
                  We offer a wide array of services including residential and commercial conveyancing, institutional and private lending and financing for purchases and refinances,
                  leasing, zoning, development, land use and distressed real estate solutions.
                </p>
              </div>

              <div className="service-contact service-card__content">
                <a href="#contact" className="mlf-btn w-100">Contact Us</a>
              </div>
            </div>
          </div>

          <div className="col-sm-8 mx-auto mx-lg-0 col-lg-4 d-flex">
            <div className="text-center service-card service-card--spacing">
              <div className="service-header">
                <span className="material-icons-outlined mlf-icon mlf-icon-court" aria-hidden>gavel</span>
                <h4 className="service-heading">Business &<br className="d-none d-inline-sm" /> Corporate Law</h4>
              </div>

              <div className="service-content service-card__content">
                <p>
                  When it comes to Business and Corporate Law, The Law Offices of Thomas W. Madonna is fully equipped to serve you no matter how large or small the issue.
                  Throughout the years we have provided services for incorporated and unincorporated business entities, partnerships and limited liability companies (LLC).
                </p>
              </div>

              <div className="service-contact service-card__content">
                <a href="#contact" className="mlf-btn w-100">Contact Us</a>
              </div>
            </div>
          </div>

          <div className="col-sm-8 mx-auto mx-lg-0 col-lg-4 d-flex">
            <div className="text-center service-card service-card--spacing probate">
              <div className="service-header">
                <span className="material-icons-outlined mlf-icon mlf-icon-script" aria-hidden>receipt_long</span>
                <h4 className="service-heading">Probate & Estate Services</h4>
              </div>

              <div className="service-content service-card__content">
                <p>
                  We recognize the potential stresses and difficulties that can arise when dealing with probate and estate matters.
                  Let us provide necessary guidance to you and your family for wills, living wills, powers of attorney and estate/trust planning and administration.
                  Contact us today to schedule your consultation.
                </p>
              </div>

              <div className="service-contact service-card__content">
                <a href="#contact" className="mlf-btn w-100">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Services };
