import * as React from "react"
import { Header } from "./Header"
import { Intro } from "./Intro"
import { Services } from "./Services"
import TestimonialClient from "./TestimonialClient"
import { Bio } from "./Bio";
import { TestimonialRealEstate } from "./TestimonialRealEstate"
import { Contact } from "./Contact"
import { Footer } from "./Footer"

import "./layout.scss"

const Layout = () => {
  return (
    <div className="container-xxl px-0">
      <Header  />

      <Intro />

      <Services />

      <TestimonialClient />

      <Bio />

      <TestimonialRealEstate />

      <Contact />

      <Footer />
    </div>
  )
}

export default Layout;
