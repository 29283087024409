import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Rhode Island Lawyer | The Law Offices of Thomas W. Madonna</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Contact Rhode Island Lawyer Thomas W. Madonna for expert legal advice in real estate, commercial, business and corporate law
          as well as probate and estate administration." />
        <meta name="keywords" content="rhode island lawyer, ri lawyer, ri real estate lawyer, ri commercial law lawyer, ri business law lawyer, ri corporate law lawyer, ri probate lawyer, ri estate lawyer,
          real estate, ri commercial law, ri business law, ri corporate law, ri probate law, ri estate administration, lawyer ri" />
        <link rel="canonical" href="https://madonnalawfirm.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#9f0101" />
        <meta property="og:url" content="https://madonnalawfirm.com"  />
        <meta property="og:title" content="Rhode Island Lawyer | The Law Offices of Thomas W. Madonna" />
        <meta property="og:description" content="Contact Rhode Island Lawyer Thomas W. Madonna for expert legal advice in real estate, commercial, business and corporate law
          as well as probate and estate administration." />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-75468209-1"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-75468209-1');
          `}
        </script>
      </Helmet>
      <Layout />
    </>
  )
}

export default IndexPage
