import React from 'react';

interface TestimonialClientProps {

}

const TestimonialClient = () => {
  return (
    <section className="testimonial">
      <div className="testimonial-col-large">
        <div className="testimonial-content">
          <blockquote className="testimonial-quote">
            The Law Offices of Thomas W. Madonna has proven time and time again to have the clients' best interest as
            their first concern. Their experience, availability and competence are the reasons why I retain their
            services for my legal needs.
          </blockquote>

          <cite className="testimonial-author">&mdash; Mary from Warwick</cite>
        </div>
      </div>

      <div className="testimonial-col-small">
        <div className="testimonial-overlay"></div>
      </div>
    </section>
  );
};

export default TestimonialClient;
